import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import { Layout, Section, Block } from '../components'
import { media } from '../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../styles/global'
import { parseACF, getImageCrop } from '../utils'
import { render } from 'react-dom'
import ProgressiveImage from 'react-progressive-image'
import { motion, transform } from 'framer-motion'

const Pricing = (props) => {
    const { x, y } = useWindowScroll()
    const { width, height } = useWindowSize()
    const [activeIndex, setActiveIndex] = useState(0)

    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    console.log(data)

    // Intro

    const renderIntro = () => {
        return (
            <Intro>
                <Wrap>
                    <Title>Our Pricing</Title>
                    <Text
                        dangerouslySetInnerHTML={{ __html: data.introduction }}
                    />
                </Wrap>
            </Intro>
        )
    }

    // Plans

    const renderPlans = () => {       
        const types =
            data.plans &&
            data.plans.map((item, i) => {
                return (
                    <NavItem
                        key={i}
                        active={activeIndex == i}
                        onClick={() => setActiveIndex(i)}
                    >
                        {item?.type?.title}
                    </NavItem>
                )
            })

        const classes = data.plans && data.plans.map((item, i) => {
            const sections = item.sections && item.sections.map((item, i) => { 
                const classItems = item.items && item.items.map((item, i) => {
                        return (
                            <Block
                                display
                                key={i}
                                layout={'card'}
                                title={item.title}
                                meta={item.acf.intro}
                                description={item.acf.short_description}
                                price={'$' + item.acf.price}
                                useLink={item.acf.mindbody_link != ''}
                                link={item.acf.mindbody_link}
                                priceNote={item.acf.price_note}
                                subHeading={item.acf.sub_heading}
                            />
                        )
                    })

                    return (
                        <>
                            <Meta>
                                <Title>{item.title}</Title>
                                <Text>{item.description}</Text>
                            </Meta>

                            <CardList>{classItems}</CardList>
                        </>
                    )
                })

            return (
                <>
                    {activeIndex == i && (
                        <Content key={i}>{sections}</Content>
                    )}
                </>
            )
        })

        return (
            <Plans>
                <Title>The Plans</Title>
                <Navigation>{types}</Navigation>
                {classes}
            </Plans>
        )
    }

    // Private Pilates

    const renderPrivatePilates = () => {
        if (!data.offer_banner_display) return

        return (
            <PrivatePilates>
                <Wrap>
                    <Title>{data.offer_banner_heading}</Title>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: data.offer_banner_text,
                        }}
                    />

                    {data.offer_banner_use_button_link && (
                        <Button
                            as={'a'}
                            href={data.offer_banner_button_link}
                            target="_blank"
                        >
                            {data.offer_banner_button_link_text}
                        </Button>
                    )}
                </Wrap>
                <Wrap>
                    {data.offer_banner_image && (
                        <Image
                            key={data.offer_banner_image?.sizes?.medium2}
                            src={data.offer_banner_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </Wrap>
            </PrivatePilates>
        )
    }

    // Teachers

    const renderTeachers = () => {
        if (!data.offer_card_display) return

        return (
            <Teachers>
                <Title>{data.offer_card_section_heading}</Title>
                <CardList>
                    <Block
                        display
                        layout={'card'}
                        title={data.offer_card_heading}
                        meta={data.card_offer_intro}
                        description={data.offer_card_text}
                        price={data.offer_card_button_link_note}
                        useLink={data.offer_card_use_button_link}
                        link={data.offer_card_button_link}
                        linkText={data.offer_card_button_link_text}
                        color={'#f7efd2'}
                    />
                </CardList>
            </Teachers>
        )
    }

    // Gift Cards

    const renderGiftCards = () => {
        if (!data.offer_inset_display) return

        return (
            <GiftCards>
                <Wrap>
                    {data.offer_inset_image && (
                        <Image
                            key={data.offer_inset_image?.sizes?.medium2}
                            src={data.offer_inset_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </Wrap>

                <Wrap>
                    <Title>{data.offer_inset_heading}</Title>
                    <SubHeading>{data.offer_inset_subheading}</SubHeading>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: data.offer_inset_text,
                        }}
                    />

                    {data.offer_inset_use_button_link && (
                        <Button
                            as={'a'}
                            href={data.offer_inset_button_link}
                            target="_blank"
                        >
                            {data.offer_inset_button_link_text}
                        </Button>
                    )}
                </Wrap>
            </GiftCards>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Container>
                {renderIntro()}
                {renderPlans()}
                {renderPrivatePilates()}
                {renderTeachers()}
                {renderGiftCards()}
            </Container>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const SubHeading = styled.div``
const Title = styled.h1``
const Text = styled.div``
const Wrap = styled.div``
const Slide = styled.div``
const ImageItem = styled.div``
const Icon = styled.div``
const Button = styled.div``
const Navigation = styled.div``
const CardList = styled.div``
const Meta = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    Margin-top: 200px;
`

// Intro

const Intro = styled.div`
    margin-bottom: 20px;
    display: flex;

    ${media.tablet`
		flex-direction: column;	
		margin-bottom: 80px;
	`}

    ${Wrap} {
        flex-basis: 100%;

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;
        }
        ${Text} {
            ${type.body};
            max-width: 80%;

            ${media.tablet`
				max-width: 100%;	
			`}
        }
    }
`

// Plans

const Plans = styled.div`
    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;
		`}
    }

    ${Navigation} {
        display: flex;

        ${media.tablet`
			flex-wrap: wrap;	
		`}
    }
`

const NavItem = styled.div`
    ${button};
    margin-right: 24px;
    background: ${colours.lightGreen};

    ${media.tablet`
		margin-right: 8px;	
		margin-bottom: 12px;	
	`}

    &:hover {
        &:before {
            display: none;
        }
    }

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.black};
                color: ${colours.white};
                border: 3px solid ${colours.black};

                &:hover {
                    background: ${colours.black};
                }
            `
    }}
`

const Content = styled.div`
    border-top: 3px solid ${colours.black};
    margin-top: 44px;
    padding-top: 32px;

    ${media.tablet`
		margin-top: 20px;
		border-top: 2px solid ${colours.black};
	`}

    ${Meta} {
        display: flex;
        flex-direction: column;
        max-width: 50%;

        ${media.tablet`
			max-width: 100%;	
		`}

        ${Title} {
            ${type.body};
            padding-bottom: 32px;

            ${media.tablet`
				padding-bottom: 20px;
			`}
        }

        ${Text} {
            font-size: 21px;

            ${media.tablet`
				font-size: 18px;	
			`}
        }
    }

    ${CardList} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        ${media.tablet`
			margin-bottom: 0px;
		`}
    }
`

// Private Pilates

const PrivatePilates = styled.div`
    background-color: ${colours.orange};
    padding: 32px;
    box-sizing: border-box;

    margin: 0 -30px 20px -30px;
    display: flex;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    margin-bottom: 80px;

    ${media.tablet`
		flex-direction: column;	
		margin: 0 -16px 80px -16px;
		padding: 20px;
	`}

    &:hover {
        background-color: ${colours.lightPurple};
    }

    ${Wrap} {
        flex-basis: 100%;

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;
        }

        ${Text} {
            ${type.body};
            max-width: 80%;

            ${media.tablet`
				max-width: 100%;	
			`}
        }

        ${BGImage} {
            ${bgIcon};
            width: 100%;
            height: 100%;
            border-radius: 100%;

            ${media.tablet`
				min-height: 382px;	
			`}
        }

        ${Button} {
            ${button};
            color: ${colours.black};
        }
    }
`

// Teachers

const Teachers = styled.div`
    display: flex;
    flex-direction: column;

    ${Title} {
        ${type.body};
        padding-bottom: 42px;
    }

    ${CardList} {
        display: flex;
    }
`

// Gift Cards

const GiftCards = styled.div`
    background-color: ${colours.white};
    border: 3px solid ${colours.black};
    padding: 60px 52px;
    box-sizing: border-box;
    margin: 100px 150px;

    display: flex;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    ${media.desktopSmall`
		margin: 50px;
	`}

    ${media.tablet`
		flex-direction: column;	
		margin: 20px -16px 0 -16px;
		padding: 20px 20px 30px;
		border-right: 0;
		border-bottom: 0;
		border-left: 0;
		background-color: ${colours.white};
	`}

    &:hover {
        background-color: ${colours.lightBeige};
    }

    ${Wrap} {
        flex-basis: 100%;
        padding: 0 20px;

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;
        }
        ${SubHeading} {
            ${type.heading3};
            padding-bottom: 20px;
        }
        ${Text} {
            max-width: 80%;

            ${media.tablet`
				max-width: 100%;	
			`}

            p {
                font-size: 21px;
            }
        }
        ${BGImage} {
            ${bgIcon};
            width: 100%;
            height: 100%;

            min-width: 300px;
            min-height: 300px;

            ${media.tablet`
				min-height: 382px;	
			`}
        }
        ${Button} {
            ${button};
            color: ${colours.black};
        }
    }
`

// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "pricing" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Pricing
